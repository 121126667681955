import { ApolloClient, HttpLink, split, from, InMemoryCache, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { Observable, getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'
import { t } from 'i18next'
import { DEPLOY_ENV, GRAPHQL_URL, GRAPHQL_WS_URL } from 'src/config-global'
import { getCredentials, refreshLoginSession } from 'src/auth/utils'
import { ConnectionStatus, subscriptionConnectionStatus, websocketLastSeenSeverTime } from './localState'
import { AmbientInMemoryCache } from './cache'

const MAX_RETRY_WAIT_MS = 5_000
export const KEEP_ALIVE_TTL_MS = 5_000

/**
 * This function is used to convert a promise to an observable in order to
 * use async/await in the errorLink
 * @param promise
 * @returns
 */
function promiseToObservable<T>(promise: Promise<T>) {
  return new Observable<T>((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return
        }
        subscriber.next(value)
        subscriber.complete()
      },
      (err) => {
        subscriber.error(err)
      }
    )
  })
}

/**
 * Handle errors such as refreshing tokens
 *
 * !Note: This will be run twice in refresh token scenarios if the query
 * has the fetchPolicy set to 'cache-and-network'. This is because the query
 * @returns a new error link
 */
function createErrorLink() {
  console.log('Creating error link')
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    const extensionError = (graphQLErrors?.[0]?.extensions as { code: number }) ?? { code: undefined }
    if (
      graphQLErrors?.some((e) => extensionError.code === 401) ||
      graphQLErrors?.some((e) => e.message === 'Auth token expired') ||
      networkError?.message.includes('Auth token expired')
    ) {
      const oldHeaders = operation.getContext().headers

      // reauthenticate and retry the request
      return promiseToObservable(refreshLoginSession()).flatMap(
        (tokenData?: { accessToken: string; refreshToken: string }) => {
          if (!tokenData?.accessToken) {
            throw new Error(t('Failed to get new tokens on refresh'))
          }
          // update for current operation
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: tokenData.accessToken,
            },
          })

          // retry the request, returning the new observable
          return forward(operation)
        }
      )
    }
    return forward(operation)
  })

  return errorLink
}

function createAuthLink() {
  return setContext(async (_, { headers }) => {
    const token = getCredentials().accessToken

    return {
      headers: {
        ...headers,
        authorization: token,
      },
    }
  })
}

/**
 * Create an apollo client with the correct links
 * @returns
 */
export const createApolloClient = () => {
  // handles http communication
  const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
    // send cookies with requests
    credentials: 'include',
  })

  // handles websocket communication for subscriptions
  const wsLink = new GraphQLWsLink(
    createClient({
      disablePong: true,
      keepAlive: KEEP_ALIVE_TTL_MS,
      url: () => GRAPHQL_WS_URL,
      // this is pulled from the official retry policy with an additional MAX added by me
      async retryWait(retries) {
        let retryDelay = 1000 * 2 ** retries
        const minJitterMS = 300
        const jitter = Math.floor(Math.random() * 1000) + minJitterMS
        retryDelay = Math.min(retryDelay, MAX_RETRY_WAIT_MS) + jitter

        await new Promise((resolve) => setTimeout(resolve, retryDelay))
      },
      retryAttempts: Infinity,
      shouldRetry: () => {
        subscriptionConnectionStatus(ConnectionStatus.RECONNECTING)
        return true
      },
      connectionParams: () => {
        const token = getCredentials()?.accessToken
        return {
          authToken: token,
        }
      },
      // keep track of connection status
      on: {
        pong: () => {
          websocketLastSeenSeverTime(new Date())
        },
        connecting: () => {
          if (subscriptionConnectionStatus() === ConnectionStatus.RECONNECTING) {
            // ! TODO
            // AlertManager.message('Reconnecting...', 'info', MAX_RETRY_WAIT_MS * 2, 'reconnecting-websocket')
          }
        },
        connected: () => {
          // treat reconnection differently than initial connection
          if (subscriptionConnectionStatus() === ConnectionStatus.RECONNECTING) {
            // ! TODO
            // AlertManager.dismiss('reconnecting-websocket')
            // AlertManager.message('Connected', 'success', 2000, 'reconnecting-websocket')
            subscriptionConnectionStatus(ConnectionStatus.RECONNECTED)
          } else {
            subscriptionConnectionStatus(ConnectionStatus.CONNECTED)
          }
        },
        closed: (event: unknown) => {
          // if we are not trying to reconnect, just close the connection
          if (subscriptionConnectionStatus() !== ConnectionStatus.RECONNECTING) {
            subscriptionConnectionStatus(ConnectionStatus.DISCONNECTED)
          }
        },
      },
    })
  )

  // choose the correct transport based on operation. Subscription operations will use ws, all others http
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  const client = new ApolloClient({
    connectToDevTools: DEPLOY_ENV === 'development' || DEPLOY_ENV === 'local',
    cache: AmbientInMemoryCache,
    link: from([createAuthLink(), createErrorLink(), splitLink]),
  })

  // store stage in local storage
  return client
}

/**
 * This function is used to restart the websocket connection for a given apollo client.
 * This is useful when the connection is lost and needs to be re-established or when the
 * authentication session is updated.
 *
 * @param client the apollo client to restart the websocket connection for
 */
export function restartWebsocketConnection(client: ApolloClient<unknown>) {
  const getChildLinks = (link: ApolloLink): ApolloLink[] => {
    const links = []
    if (link.left) {
      links.push(link.left)
      links.push(...getChildLinks(link.left))
    }
    if (link.right) {
      links.push(link.right)
      links.push(...getChildLinks(link.right))
    }
    return links
  }
  // find the websocket link in the tree
  const linkList = getChildLinks(client.link)
  const wsLink = linkList.find((link) => link instanceof GraphQLWsLink) as GraphQLWsLink
  wsLink.client.terminate()
}

/**
 * @returns a public apollo client that does not require authentication
 */
export function getPublicClient() {
  const publicApolloClient = new ApolloClient({
    uri: `${GRAPHQL_URL}/public`,
    cache: new InMemoryCache(),
  })
  return publicApolloClient
}
