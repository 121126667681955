import React, { useState, memo, useCallback, useRef, useEffect } from 'react'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
// @mui
import { useTheme, alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Dialog, { dialogClasses } from '@mui/material/Dialog'
// hooks
import ListItemButton from '@mui/material/ListItemButton'
import { useQuery } from '@apollo/client'
import ListItemText from '@mui/material/ListItemText'
import { useSnackbar } from 'notistack'
import { InputLabel, ListItem, Typography } from '@mui/material'
import { useBoolean } from 'src/hooks/use-boolean'
import { useEventListener } from 'src/hooks/use-event-listener'
// components
import Label from 'src/components/label'
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar'
import { useRouter } from 'src/routes/hooks'
import SearchNotFound from 'src/components/search-not-found'
//
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard'
import { getMeQuery } from 'src/graphql/queries'
import { getCredentials } from 'src/auth/utils'
import { useEmit, useSubscribe } from 'src/hooks/use-event-bus'
import { useLocalStorage } from 'src/hooks/use-local-storage'
import ResultItem from './result-item'
import { useNavData } from '../../dashboard/config-navigation'
import { applyFilter, groupedData, getAllItems } from './utils'

// ----------------------------------------------------------------------

type SearchbarResultState = 'notFound' | 'debug' | 'search' | 'debug:internal'

function Searchbar() {
  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>('searchHistory', [])
  const [searchHistoryIndex, setSearchHistoryIndex] = useState<number>(-1)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [selectedItem, setSelectedItem] = useState<number>()
  const theme = useTheme()

  const router = useRouter()

  const search = useBoolean()

  const [searchQuery, setSearchQuery] = useState('')

  const navData = useNavData()

  const dataFiltered = applyFilter({
    inputData: getAllItems({ data: navData }),
    query: searchQuery,
  })

  const handleClose = useCallback(() => {
    search.onFalse()
    setSearchQuery('')
    setSearchHistoryIndex(-1)
  }, [search])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'k' && event.metaKey) {
        search.onToggle()
        setSearchQuery('')
      } else if (event.key === 'ArrowDown') {
        // scroll through the list of results and focus the previous/next one
        setSelectedItem((selectedItem ?? -1) + 1)
        const index = (selectedItem ?? -1) + 1
        if (index >= dataFiltered.length) {
          // focus the input when the last item is selected and the user presses down
          inputRef.current?.focus()
        } else {
          setSelectedItem(Math.min(index, dataFiltered.length - 1))
        }
      } else if (event.key === 'ArrowUp') {
        // scroll through the list of results and focus the previous/next one
        const index = (selectedItem ?? dataFiltered.length) - 1
        if (index < 0) {
          // focus the input when the first item is selected and the user presses up
          inputRef.current?.focus()
        } else {
          setSelectedItem(Math.max(index, 0))
        }
      }
    },
    [dataFiltered.length, search, selectedItem]
  )

  useEventListener('keydown', handleKeyDown)

  const handleClick = useCallback(
    (path: string) => {
      if (path.includes('http')) {
        window.open(path)
      } else {
        router.push(path)
      }
      handleClose()
    },
    [handleClose, router]
  )

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value)
  }, [])

  const emitRunDebugCmd = useEmit('debugcmd:run')
  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        let i = searchHistoryIndex + (event.key === 'ArrowUp' ? 1 : -1)
        if (i === -1) {
          setSearchQuery('')
          setSearchHistoryIndex(i)
        } else if (i >= 0) {
          i = Math.max(0, Math.min(i, searchHistory.length - 1))
          const recentSearch = searchHistory[i]
          if (recentSearch) {
            setSearchQuery(recentSearch)
            setSearchHistoryIndex(i)
          }
        }
        event.preventDefault()
        event.stopPropagation()
      } else if (event.key === 'Enter' || event.key === 'Tab') {
        setSearchHistory((prev) => {
          const newHistory = [...prev]
          newHistory.unshift(searchQuery)
          // restrict length to 30 items
          newHistory.splice(30)
          return newHistory
        })
        setSearchHistoryIndex(-1)
        if (searchQuery.startsWith('$')) {
          const command = /^\$\s*(.*)$/.exec(searchQuery)
          if (command?.[1]) {
            // debug command
            emitRunDebugCmd({ cmd: command?.[1] })
            event.preventDefault()
            event.stopPropagation()
            return
          }
        }

        setSelectedItem(0)
        event.preventDefault()
        event.stopPropagation()
      }
    },
    [emitRunDebugCmd, searchHistory, searchHistoryIndex, searchQuery, setSearchHistory]
  )

  let resultState: SearchbarResultState = 'search'
  if (searchQuery.startsWith('$')) {
    resultState = 'debug:internal'
  } else if (dataFiltered.length === 0) {
    resultState = 'notFound'
  }

  const renderItems = () => {
    const data = groupedData(dataFiltered)

    let listIndex = -1
    return Object.keys(data)
      .sort((a, b) => b.localeCompare(a))
      .map((group, index) => (
        <List key={group || index} disablePadding>
          {data[group].map((item, index2) => {
            listIndex += 1
            const { title, path } = item

            const partsTitle = parse(title, match(title, searchQuery))
            const partsPath = parse(path, match(path, searchQuery))
            return (
              <ResultItem
                isSelected={selectedItem !== undefined && selectedItem === listIndex}
                path={partsPath}
                title={partsTitle}
                key={`${title}${path}`}
                groupLabel={searchQuery && group}
                onClickItem={() => handleClick(path)}
              />
            )
          })}
        </List>
      ))
  }

  const renderButton = (
    <Stack direction="row" alignItems="start">
      <IconButton
        className="override-todesktop-drag"
        intercom-data-attribute="toggle-searchbar"
        onClick={search.onTrue}
      >
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {/* {mdUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>} */}
    </Stack>
  )

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
            maxHeight: '90%',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            inputRef={inputRef}
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            onKeyDown={handleInputKeyDown}
            // clear the selected item when the input is focused
            onFocus={() => setSelectedItem(undefined)}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>
        <Scrollbar tabIndex={undefined} sx={{ p: 3, pt: 2, minHeight: 400 }}>
          {resultState === 'notFound' && <SearchNotFound query={searchQuery} sx={{ py: 10 }} />}
          {resultState === 'search' && renderItems()}
          {['debug', 'debug:internal'].includes(resultState) && <DebugOptions selectedItem={selectedItem ?? -1} />}
        </Scrollbar>
      </Dialog>
    </>
  )
}

function DebugOptions({ selectedItem }: { selectedItem: number }) {
  const emitFeatureUpdated = useEmit('feature:updated')
  const { data, loading } = useQuery(getMeQuery, {
    fetchPolicy: 'cache-first',
  })
  const { copy } = useCopyToClipboard()
  const { enqueueSnackbar } = useSnackbar()

  const copyText = useCallback(
    (text: string) => {
      copy(text)
      enqueueSnackbar('Copied to clipboard', { variant: 'success' })
    },
    [copy, enqueueSnackbar]
  )

  const listFeatures = useCallback(() => {
    const features = Object.keys(localStorage)
      .filter((key) => key.startsWith('feature:'))
      .sort()
      .map((key) => `${key.replace('feature:', '')}=${localStorage.getItem(key)}`)
    return features.join(', ')
  }, [])

  const showCommandOutput = useCallback(
    (message?: string, details?: React.ReactNode) => {
      enqueueSnackbar({
        message: (
          <Stack spacing={1} width={1}>
            {message && <Typography variant="overline">{message}</Typography>}
            <Box width={1} p={1} borderRadius={1}>
              {details}
            </Box>
          </Stack>
        ),
        autoHideDuration: 7000,
        variant: 'default',
        transitionDuration: {
          enter: 50,
          exit: 50,
        },
      })
    },
    [enqueueSnackbar]
  )

  const creds = getCredentials()

  const processCommand = useCallback(
    (command: string) => {
      const [commandName, ...args] = command.split(' ')
      if (commandName === 'help') {
        showCommandOutput(
          'Available commands:',
          <Stack spacing={1}>
            <Typography variant="body2" component="div">
              <code>feature on|off|list</code>
            </Typography>
          </Stack>
        )
      } else if (commandName === 'feature') {
        if (args[0] === 'on') {
          localStorage.setItem(`feature:${args[1]}`, 'true')
          emitFeatureUpdated({ feature: args[1], enabled: true })
          showCommandOutput('feature turned on. Enabled features:', listFeatures())
        } else if (args[0] === 'off') {
          localStorage.removeItem(`feature:${args[1]}`)
          emitFeatureUpdated({ feature: args[1], enabled: false })
          showCommandOutput('feature turned off. Enabled features:', listFeatures())
        } else if (args[0] === 'list') {
          showCommandOutput('listing features', listFeatures())
        }
      }
    },
    [emitFeatureUpdated, listFeatures, showCommandOutput]
  )

  useSubscribe('debugcmd:run', ({ cmd }) => {
    processCommand(cmd)
  })

  if (loading) {
    return <List disablePadding>... loading ...</List>
  }

  return (
    <>
      <ListItem key="commands">
        <Typography variant="overline" mr={5}>
          Commands
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {`Type a command in the search box, use 'help' for a list of commands. Example: `}
          <code>$feature list</code>
        </Typography>
      </ListItem>
      <List disablePadding>
        <DebugItem
          isSelected={selectedItem === 0}
          key="0"
          onClickItem={() => copyText(data?.me?.id ?? '')}
          title="user id"
        >
          {data?.me?.id}
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 1}
          key="1"
          onClickItem={() => copyText(data?.me?.orgId ?? '')}
          title="org id"
        >
          {data?.me?.orgId}
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 2}
          key="2"
          onClickItem={() => copyText(data?.me?.email ?? '')}
          title="email"
        >
          {data?.me?.email}
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 3}
          key="3"
          onClickItem={() => copyText(data?.getMyActiveRoleNames.join(',') ?? '')}
          title="roles"
        >
          {data?.getMyActiveRoleNames.join(',')}
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 3}
          key="5"
          onClickItem={() => copyText(JSON.stringify(data?.getMySubscriptionStatus ?? '{}'))}
          title="subscription"
        >
          <Stack>
            <Stack direction="row" spacing={2}>
              <InputLabel>Product</InputLabel>
              {data?.getMySubscriptionStatus?.productName}
            </Stack>
            <Stack direction="row" spacing={2}>
              <InputLabel>Is trial</InputLabel>
              {data?.getMySubscriptionStatus?.isTrial ? 'true' : 'false'}
            </Stack>
            <Stack direction="row" spacing={2}>
              <InputLabel>End Date</InputLabel>
              {data?.getMySubscriptionStatus?.endDate}
            </Stack>
          </Stack>
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 4}
          key="6"
          onClickItem={() => copyText(creds.accessToken ?? '')}
          title="access token"
        >
          <Typography sx={{ lineBreak: 'anywhere' }}>{creds.accessToken}</Typography>
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 4}
          key="7"
          onClickItem={() => copyText(creds.refreshToken ?? '')}
          title="refesh token"
        >
          <Typography sx={{ lineBreak: 'anywhere' }}>{creds.refreshToken}</Typography>
        </DebugItem>
      </List>
    </>
  )
}

function DebugItem({
  title,
  onClickItem,
  isSelected,
  children,
}: {
  title: string
  onClickItem: () => void
  isSelected: boolean
  children: React.ReactNode
}) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({ block: 'nearest' })
      ref.current.focus()
    }
  }, [isSelected])
  return (
    <ListItemButton
      key={title}
      ref={ref}
      onClick={onClickItem}
      sx={{
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'transparent',
        borderBottomColor: (theme) => theme.palette.divider,
        '&:hover, &:focus': {
          borderRadius: 1,
          borderColor: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box minWidth={100}>
          <ListItemText
            primaryTypographyProps={{
              typography: 'subtitle2',
              sx: { textTransform: 'capitalize' },
            }}
            secondaryTypographyProps={{ typography: 'caption' }}
            primary={title}
          />
        </Box>
        <Box>{children}</Box>
      </Stack>
    </ListItemButton>
  )
}

export default memo(Searchbar)
