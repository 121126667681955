import { ActionItemImportance, ActionItemStatus } from 'src/__generated__/graphql'

export const ACTION_ITEM_TABLE_PERSISTANT_STATE_KEYS = ['default', 'dashboard-home', 'projects'] as const
export type PersistantCacheKey = (typeof ACTION_ITEM_TABLE_PERSISTANT_STATE_KEYS)[number]

export interface RowDataType {
  id: string
  summaryId?: string | null
  postId?: string | null
  description: string
  owner: ActionItemOwner
  keyDate: Date | null
  status: ActionItemStatus
  importance: ActionItemImportance
  urgent: boolean
  reporterId: string | null
  createdAt: Date | null
  meetingId?: string | null | undefined
  projectId: string | null
}

export interface ActionItemOwner {
  id: string | null
  name: string | null
  type: 'actor' | 'text'
}

export type BulkActionItemOwner = Omit<ActionItemOwner, 'type'> & { type: 'actor' | 'text' | 'multiple' }

export type NewActionItem = Omit<RowDataType, 'id' | 'summaryId'>

export type FilterSettings = {
  status: ActionItemStatus[]
  importance: ActionItemImportance[]
  hiddenColumns: string[]
  date: 'all' | 'overdue' | 'today' | 'next 7 days'
  assignedToUserId?: string | null | undefined
}

export const TABLE_HEAD = [
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    sx: { pl: 1, minWidth: '300px !important' },
    minWidth: 300,
  },
  { id: 'projectId', label: 'Project', align: 'left', maxWidth: 200 },
  { id: 'owner', label: 'Owner', align: 'left', maxWidth: 200 },
  { id: 'importance', label: 'Importance', align: 'center', width: 50 },
  { id: 'urgent', label: 'Urgent', align: 'center', width: 50 },
  { id: 'keyDate', label: 'Date', align: 'center' },
  { id: 'status', label: 'Status', align: 'center', width: 40, minWidth: 10 },
  { id: 'createdAt', label: 'Created', align: 'center' },
  { id: 'action', label: '', align: 'right', width: 20, minWidth: 10 },
]

export const DEFAULT_FILTER_COLUMNS: string[] = ['createdAt']
export const DEFAULT_FILTER_STATUS = [ActionItemStatus.Open, ActionItemStatus.Inprogress]
export const DEFAULT_FILTER_IMPORTANCE = [
  ActionItemImportance.None,
  ActionItemImportance.Low,
  ActionItemImportance.High,
]
export const DEFAULT_FILTER_DATE = 'all' as FilterSettings['date']
