import { useState } from 'react'
import { useSnackbar } from 'src/components/snackbar'

// ----------------------------------------------------------------------

type CopiedValue = string | null
type CopyTypes = 'text/plain' | 'text/html'
type CopyWithFormatInput = [blob: string, format: CopyTypes]

type CopyFn = (text: string, ...formats: CopyTypes[]) => Promise<boolean>
type CopyManyTypesFn = (...input: CopyWithFormatInput[]) => Promise<boolean>

type ReturnType = {
  copy: CopyFn
  copyManyTypes: CopyManyTypesFn
  copiedText: CopiedValue
}

export function useCopyToClipboard(showSnackbar?: Boolean): ReturnType {
  const { enqueueSnackbar } = useSnackbar()
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

  const copy: CopyFn = async (text, ...formats) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      if (formats.length > 0) {
        const dict = formats.reduce(
          (acc, format) => {
            acc[format] = new Blob([text], { type: format })
            return acc
          },
          {} as Record<CopyTypes, Blob>
        )
        navigator.clipboard.write([new ClipboardItem(dict)])
      } else {
        await navigator.clipboard.writeText(text)
      }
      setCopiedText(text)
      if (showSnackbar) {
        enqueueSnackbar('Copied to clipboard', { variant: 'success' })
      }
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false
    }
  }

  const copyManyTypes: CopyManyTypesFn = async (...input) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      if (input.length > 0) {
        const dict = input.reduce(
          (acc, [blob, format]) => {
            acc[format] = new Blob([blob], { type: format })
            return acc
          },
          {} as Record<CopyTypes, Blob>
        )
        navigator.clipboard.write([new ClipboardItem(dict)])
      }
      if (showSnackbar) {
        enqueueSnackbar('Copied to clipboard', { variant: 'success', autoHideDuration: 1000 })
      }
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false
    }
  }

  return { copiedText, copy, copyManyTypes }
}
