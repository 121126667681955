import { useMemo, useState } from 'react'
// routes
import { useQuery } from '@apollo/client'
import { paths } from 'src/routes/paths'
// locales
import { useLocales } from 'src/locales'
// components
import SvgColor from 'src/components/svg-color'
import Label from 'src/components/label'
import Iconify from 'src/components/iconify'
import { PostAuthorFilter } from 'src/types/feed'
import { getMeetingSummaryDrafts } from 'src/graphql/queries'
import { NavSectionProps } from 'src/components/nav-section/types'

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
)

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  schedule: <Iconify icon="fluent:calendar-24-filled" width={30} />,
}

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales()
  const [draftsBadge, setDraftsBadge] = useState<string | undefined>(undefined)
  useQuery(getMeetingSummaryDrafts, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const { meetingSummaryDrafts } = data
      if (meetingSummaryDrafts && meetingSummaryDrafts.length > 0) {
        setDraftsBadge(meetingSummaryDrafts.length.toString())
      } else {
        setDraftsBadge(undefined)
      }
    },
  })

  const data = useMemo<NavSectionProps['data']>(
    () => [
      {
        subheader: t('home'),
        items: [
          {
            title: t('dashboard'),
            path: paths.dashboard.home,
            icon: ICONS.dashboard,
          },
          // Projects
          {
            title: 'Projects',
            icon: ICONS.folder,
            path: paths.dashboard.projects.root,
            alwaysStartOpen: true,
          },
          // MEETING Series
          {
            title: t('nav.meeting_series'),
            icon: ICONS.calendar,
            path: paths.dashboard.meetingSeries.list,
            alwaysStartOpen: true,
          },
          // FEED
          {
            title: t('feed'),
            path: paths.dashboard.post.root,
            icon: ICONS.blog,
            alwaysStartOpen: true,
            badgeText: draftsBadge,
            children: [
              { title: t('nav.latest'), path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.companyWide) },
              {
                title: t('nav.drafts'),
                path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.drafts),
                info: <DraftLabel />,
              },
              {
                title: t('nav.my_meetings'),
                path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.myMeetings),
              },
              {
                title: t('nav.my_emails'),
                path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.myEmails),
              },
              {
                title: t('nav.shared_with_me'),
                path: paths.dashboard.post.rootWithFilter(PostAuthorFilter.sharedWithMe),
              },
            ],
          },
        ],
      },
    ],
    [draftsBadge, t]
  )

  return data
}

/**
 * A label that shows the number of meeting summary drafts.
 * @returns
 */
function DraftLabel() {
  const { data } = useQuery(getMeetingSummaryDrafts, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.meetingSummaryDrafts?.length) {
    return null
  }

  return (
    <Label color="error" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
      {data.meetingSummaryDrafts.length}
    </Label>
  )
}
