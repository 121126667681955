import { FieldPolicy, InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache'
import { gql } from 'src/__generated__'
import { AclMemberRole, AttachedProject, FeedPage, ProjectDetails, ProjectListItem } from 'src/__generated__/graphql'

interface CachableFieldData {
  data: unknown
  __lastUpdated: number
}

/**
 * This is a container object for the feed cache.
 *
 * NOTE: Apollo can only diff plain objects, arrays and scalars. You CANNOT
 * diff a map or set. Failing to follow this rule will result in the
 * cache not updating properly.
 */
interface FeedCache {
  posts: any[]
  remaining: number
  // Do not use undefined in the cache! It will cause a cache miss
  // because apollo sees "undefined" as "missing in cache".
  nextCursor: string | null
}

// This is a generic field policy that will wrap field merge and read functions to
// automatically cache the data for a certain amount of time.
function wrapFieldForCaching(cacheForSeconds: number): FieldPolicy {
  return {
    read(existingData: CachableFieldData | undefined, { storage }) {
      const expiration = storage.cacheExpiration ?? 0
      if (existingData && existingData.__lastUpdated < expiration) {
        return existingData.data
      }
      return undefined // Force a network fetch
    },
    merge(_existing: CachableFieldData | undefined, incoming, { storage }) {
      storage.cacheExpiration = Date.now() + 1000 * cacheForSeconds
      return {
        data: incoming,
        __lastUpdated: Date.now(),
      }
    },
  }
}

export const AmbientInMemoryCache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'UserProfile':
        return `UserProfile:${responseObject.userId}`
      case 'ApiKey':
        return `ApiKey:${responseObject.key}`
      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
  typePolicies: {
    Query: {
      fields: {
        getDelegateInfo: wrapFieldForCaching(60 * 5),
        getAvailableTags: wrapFieldForCaching(60 * 5),
        user: {
          // if we have already retrieved the user lists using the allUsers query
          // we can use that data
          read(_: any, { args, toReference }) {
            const a = args as { id: string }
            return toReference({
              __typename: 'User',
              id: a.id,
            })
          },
        },
        feed: {
          // We will store a copy of the feed for every combination of filters.
          // This is relatively efficient as the feed only stores references to posts
          keyArgs: [['filter', ['postSource', 'authorId', 'tagIds', 'sharedWithMe']], 'searchTerms'],

          /**
           * This merge function will run when a query is run. It will also execute
           * when refetch (with merge = true) or when "fetchMore" is called.
           *
           * It will merge the existing cache with the incoming data and store it in FeedCache
           * object for future reads.
           *
           * The incoming data will be the previous cache value for the same args as defined
           * in the keyArgs above.
           *
           * @param existing the existing cache from a previous query
           * @param incoming the incoming data from the new query
           * @returns
           */
          merge(existing: FeedCache | null, incoming: FeedPage, { readField }): FeedCache {
            // figure out if the incoming data is newer or older than the existing data
            const oldestExistingPost = existing?.posts[existing.posts.length - 1]
            const oldestIncomingPost = incoming.posts[incoming.posts.length - 1]
            const result: FeedCache = {
              posts: existing?.posts.slice(0) ?? [],
              remaining: existing?.remaining ?? 0,
              nextCursor: existing?.nextCursor ?? null,
            }

            let incomingIsOlder = true
            if (oldestExistingPost && oldestIncomingPost) {
              const oldestExistingDate = new Date(
                readField('meetingStartOrPostCreateTime', oldestExistingPost) as string
              )
              const oldestIncomingDate = new Date(
                readField('meetingStartOrPostCreateTime', oldestIncomingPost) as string
              )
              incomingIsOlder = oldestExistingDate.getTime() > oldestIncomingDate.getTime()
            }

            if (incomingIsOlder) {
              // if the incoming data is older, we should overwrite the existing
              // remaining and nextCursor values
              result.remaining = incoming.remaining
              result.nextCursor = incoming.nextCursor ?? null
            }

            // deduplicate the posts in the array
            const dedupeMap = new Map<string, any>()
            // add incoming posts to the end so thay overwrite existing posts as we loop
            result.posts.push(...(existing?.posts.slice(0) ?? []))
            result.posts.push(...incoming.posts)
            result.posts.forEach((p) => dedupeMap.set(readField('id', p)!, p))

            // convert map back to array
            const resultMapArray = Array.from(dedupeMap.values())

            // sort by createdAt and return
            result.posts = resultMapArray.sort((a, b) => {
              const aDate = new Date(readField('meetingStartOrPostCreateTime', a) as string)
              const bDate = new Date(readField('meetingStartOrPostCreateTime', b) as string)
              return bDate.getTime() - aDate.getTime()
            })

            return result
          },
        },
        // cache a slim version of the project list items
        getProjects: {
          merge(_: ProjectListItem[], incoming: ProjectListItem[], { cache, readField }) {
            incoming.forEach((proj) => {
              if (!proj) {
                return
              }
              const slimP: AttachedProject = {
                __typename: 'AttachedProject',
                id: readField<string>('id', proj)!,
                name: readField<string>('name', proj)!,
                orgId: readField<string>('orgId', proj)!,
                role: readField<AclMemberRole>('role', proj),
              }
              cache.writeFragment({
                id: `AttachedProject:${proj.id}`,
                fragment: gql(/* GraphQL */ `
                  fragment attachedProject on AttachedProject {
                    id
                    name
                    orgId
                    role
                  }
                `),
                data: slimP,
              })
            })

            return incoming
          },
        },
        // cache a slim version of the project list items
        getProject: {
          merge(_: ProjectDetails, incoming: any, { cache, readField }) {
            if (!incoming) {
              return null
            }
            const slimP: AttachedProject = {
              __typename: 'AttachedProject',
              id: readField<string>('id', incoming)!,
              name: readField<string>('name', incoming)!,
              orgId: readField<string>('orgId', incoming)!,
              role: readField<AclMemberRole>('role', incoming),
            }
            cache.writeFragment({
              id: `AttachedProject:${slimP.id}`,
              fragment: gql(/* GraphQL */ `
                fragment attachedProject on AttachedProject {
                  id
                  name
                  orgId
                  role
                }
              `),
              data: slimP,
            })

            return incoming
          },
        },
        // read from cache before fetching
        // this is because other queries probably have already fetched attached project data
        getProjectSlim: {
          read(_: ProjectListItem[], { args, cache, toReference, storage }) {
            const id = args?.id as string | null | undefined
            if (!id) {
              return null
            }
            const p = cache.readFragment({
              id: `AttachedProject:${id}`,
              fragment: gql(/* GraphQL */ `
                fragment attachedProject on AttachedProject {
                  id
                  name
                  orgId
                  role
                }
              `),
            })

            return p
          },
        },
      },
    },
  },
})
