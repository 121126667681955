// apollo
import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider'

// i18n
import 'src/locales/i18n'

// scrollbar
import 'simplebar-react/dist/simplebar.min.css'

// lightbox
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

// carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// image
import 'react-lazy-load-image-component/src/effects/blur.css'

// ----------------------------------------------------------------------
import Router from 'src/routes/sections'
// theme
import ThemeProvider from 'src/theme'
// locales
import { LocalizationProvider } from 'src/locales'
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top'
// components
import ProgressBar from 'src/components/progress-bar'
import { MotionLazy } from 'src/components/animate/motion-lazy'
import SnackbarProvider from 'src/components/snackbar/snackbar-provider'
import { SettingsProvider, SettingsDrawer } from 'src/components/settings'
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt'
// services
import { AmbientIntercomProvider } from './components/intercom'
import { createApolloClient } from './graphql/apolloClient'
// config
import { DEPLOY_ENV } from './config-global'
import { DesktopAppWrapper } from './DesktopAppWrapper'
import { EventBusProvider } from './components/event-bus'

// ----------------------------------------------------------------------
const appolloClient = createApolloClient()

export default function App() {
  if (DEPLOY_ENV === 'local') {
    const charAt = `
█▀▀█ █▀▄▀█ █▀▀▄  ▀  █▀▀ █▀▀▄ ▀▀█▀▀
█▄▄█ █░▀░█ █▀▀▄ ░█░ █▀▀ █░░█ ░░█░░
▀░░▀ ▀░░░▀ ▀▀▀░ ░▀░ ▀▀▀ ▀░░▀ ░░▀░░
    `
    console.info(`%c${charAt}`, 'color: #5BE49B')
  }

  useScrollToTop()

  return (
    <EventBusProvider>
      <DesktopAppWrapper>
        <ApolloProvider client={appolloClient}>
          <AmbientIntercomProvider>
            <AuthProvider>
              <LocalizationProvider>
                <SettingsProvider
                  defaultSettings={{
                    logoColorMode: 'monochrome',
                    themeMode: 'light', // 'light' | 'dark'
                    themeDirection: 'ltr', //  'rtl' | 'ltr'
                    themeContrast: 'bold', // 'default' | 'bold'
                    themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                    themeColorPresets: 'ambient', // 'default' | 'ambient' | 'purple' | 'blue' | 'orange' | 'red'
                    themeStretch: true,
                  }}
                >
                  <ThemeProvider>
                    <MotionLazy>
                      <SnackbarProvider>
                        <SettingsDrawer />
                        <ProgressBar />
                        <AuthConsumer>
                          <Router />
                        </AuthConsumer>
                      </SnackbarProvider>
                    </MotionLazy>
                  </ThemeProvider>
                </SettingsProvider>
              </LocalizationProvider>
            </AuthProvider>
          </AmbientIntercomProvider>
        </ApolloProvider>
      </DesktopAppWrapper>
    </EventBusProvider>
  )
}
