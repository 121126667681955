// components
import { SplashScreen } from 'src/components/loading-screen'
//
import { useAuthContext } from 'src/auth/hooks'
import { useBoolean } from 'src/hooks/use-boolean'
import { useCallback, useEffect, useMemo } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { paths } from 'src/routes/paths'
import { useNavigate } from 'react-router-dom'
import type { JWTContextType } from 'src/auth/types'
import { AuthContext } from './auth-context'
import { GlobalAuthEventListeners } from './global-auth-event-listeners'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export function AuthConsumer({ children }: Props) {
  return (
    <AuthContext.Consumer>
      {(auth) => <AuthConsumerChildrenWrapper auth={auth}>{children}</AuthConsumerChildrenWrapper>}
    </AuthContext.Consumer>
  )
}

/**
 * This wraper ensures that the global auth listeners are present even if we are loading
 */
function AuthConsumerChildrenWrapper({ children, auth }: { children: React.ReactNode; auth: JWTContextType }) {
  const result = useMemo(
    () => (
      <>
        <GlobalAuthEventListeners />
        {auth.loading ? <SplashScreen /> : <FatalAuthErrorListener children={children} />}
      </>
    ),
    [children, auth.loading]
  )
  return result
}

function FatalAuthErrorListener({ children }: { children: React.ReactNode }) {
  const { fatalError, logout } = useAuthContext()
  const showDialog = useBoolean(fatalError)
  const loading = useBoolean()
  const navigate = useNavigate()

  useEffect(() => {
    showDialog.setValue(fatalError)
  }, [fatalError, showDialog])

  const handleLogout = useCallback(async () => {
    loading.onTrue()
    await logout().finally(() => {
      loading.onFalse()
      navigate(paths.auth.jwt.login(window.location.pathname))
    })
  }, [loading, logout, navigate])

  return (
    <>
      {children}
      <Dialog open={showDialog.value} onClose={showDialog.onFalse} sx={{ p: 4 }}>
        <DialogTitle>Authentication Error</DialogTitle>
        <DialogContent>
          <DialogContentText>There was an error while trying to authenticate. Please login again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Login</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
