import { useEffect, useCallback, useState } from 'react'
// routes
import { useSearchParams } from 'react-router-dom'
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hooks'
//
import { useAuthContext } from '../hooks'

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login(),
}

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function AuthGuard({ children }: Props) {
  const router = useRouter()
  const { authenticated, method } = useAuthContext()
  const [searchParams] = useSearchParams()

  const [checked, setChecked] = useState(false)

  const check = useCallback(() => {
    if (!authenticated) {
      const source = searchParams.get('source') ?? undefined
      const sourceRef = searchParams.get('source_ref') ?? undefined
      const trialCode = searchParams.get('trial_code') ?? undefined
      const queryParams = new URLSearchParams({
        returnTo: `${window.location.pathname}${window.location.search}`,
      })
      if (source) {
        queryParams.set('source', source)
      }
      if (sourceRef) {
        queryParams.set('source_ref', sourceRef)
      }
      if (trialCode) {
        queryParams.set('trial_code', trialCode)
      }
      const queryString = queryParams.toString()

      const loginPath = loginPaths[method]

      const href = `${loginPath}${queryString}`

      router.replace(href)
    } else {
      setChecked(true)
    }
  }, [authenticated, method, router, searchParams])

  useEffect(() => {
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!checked) {
    return null
  }

  return <>{children}</>
}
