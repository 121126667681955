// @mui
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { CardContent, CardActionArea, CardActions, CardHeader, Paper, keyframes } from '@mui/material'
// routes
// locales
import { useCallback } from 'react'
import Iconify from 'src/components/iconify/iconify'
import { useBoolean } from 'src/hooks/use-boolean'
import { useInFlightRecording } from 'src/hooks/use-inflight-recording'
import { useAnalytics } from 'src/components/analytics'
import MeetingBotControlsDialog from './record-meeting-dialog'

// ----------------------------------------------------------------------

const pulseKeyframes = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
`

// ----------------------------------------------------------------------

export default function MeetingBotNavControls({ mini }: { mini?: boolean }) {
  const { track } = useAnalytics()
  const dialogIsOpen = useBoolean()
  const { inFlightRecording } = useInFlightRecording({ includeScheduled: false, subscribeToMore: true })

  const handleOpenDialog = useCallback(() => {
    track('Click', { element: 'open_recording_controls', from: 'navbar' })
    dialogIsOpen.onTrue()
  }, [dialogIsOpen, track])

  const renderButton = mini ? (
    <Button
      intercom-data-attribute="record-meeting"
      aria-label="record meeting"
      onClick={handleOpenDialog}
      size="small"
      fullWidth
      sx={{
        py: 3.5,
        color: inFlightRecording ? 'error.main' : 'text.secondary',
      }}
    >
      <Stack my={2} spacing={0.5} alignItems="center" width={1}>
        {inFlightRecording ? (
          <Iconify
            icon="mdi:record"
            width={22}
            sx={{ animation: `${pulseKeyframes} 1s infinite alternate ease-in-out` }}
          />
        ) : (
          <Iconify icon="fluent:calendar-record-16-regular" width={22} />
        )}
        <Typography
          variant="caption"
          sx={{
            fontSize: 10,
            lineHeight: '16px',
          }}
        >
          {inFlightRecording ? 'Recording' : 'Record'}
        </Typography>
      </Stack>
    </Button>
  ) : (
    <Stack
      sx={{
        px: 2,
        pt: 2,
        textAlign: 'center',
      }}
    >
      <Paper
        intercom-data-attribute="meeting-bot-controls"
        sx={(theme) => ({ borderRadius: 2, p: 2, backgroundColor: theme.palette.background.paper })}
      >
        <CardActionArea sx={{ borderRadius: 1 }} onClick={dialogIsOpen.onTrue}>
          <CardHeader
            sx={{ p: 0 }}
            titleTypographyProps={{ variant: 'subtitle2' }}
            title="Record, transcribe, and summarize all your meetings"
          />
          <CardContent sx={{ py: 1 }}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Supported Platforms
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 0.5 }}>
              <Iconify icon="logos:zoom" width={40} mt={-0.75} />
              <Iconify icon="logos:microsoft-teams" width={26} />
              <Iconify icon="logos:google-meet" width={26} />
            </Stack>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ mb: 0, mt: 1, p: 0 }}>
          <Button
            intercom-data-attribute="record-meeting"
            aria-label="record meeting"
            startIcon={
              !inFlightRecording ? (
                <Iconify icon="mdi:link-plus" width={22} />
              ) : (
                <Iconify
                  icon="mdi:record"
                  width={22}
                  sx={{ animation: `${pulseKeyframes} 1s infinite alternate ease-in-out` }}
                />
              )
            }
            onClick={handleOpenDialog}
            variant="soft"
            size="small"
            fullWidth
            {...(inFlightRecording && { color: 'error' })}
          >
            {inFlightRecording ? 'Recording in progress' : 'Invite with meeting link'}
          </Button>
        </CardActions>
      </Paper>
    </Stack>
  )

  return (
    <>
      {renderButton}
      <MeetingBotControlsDialog open={dialogIsOpen.value} onClose={dialogIsOpen.onFalse} />
    </>
  )
}
