import { createContext, useContext } from 'react'
import { User } from 'src/__generated__/graphql'
import { AnalyticsEvent, AnalyticsUserProfileData, NarrowByEventName } from './types'

// ----------------------------------------------------------------------

export type AnalyticsContextState = {
  /**
   * Track an event from one of the events defined in AnalyticsEvent
   * @param event event name
   * @param data event data, if any
   */
  track: <EventName extends AnalyticsEvent['name']>(
    name: EventName,
    data: NarrowByEventName<AnalyticsEvent, EventName>
  ) => void

  /**
   * Track a page view
   * @param path the path of the page
   */
  trackPageView: () => void

  /**
   * Register data to be sent with every event
   * @param data a dictionary of data to be sent with every event
   */
  updateAnalyticsProfile: (data?: Partial<AnalyticsUserProfileData>) => void

  /**
   * Identify the current user
   * @param user the current user
   */
  identifyUser: (user: User) => void

  /**
   * Clear tracking state when a user logs out
   */
  onLogout: () => void
}

export const AnalyticsContext = createContext<AnalyticsContextState>({} as AnalyticsContextState)

/**
 * A hook to access the AnalyticsContext
 * @returns various methods and state to help with analytic telemetry
 */
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)

  if (!context) throw new Error('useAnalytics must be use inside AnalyticsProvider')

  return context
}
