import './desktop-app.css'

/**
 * Simple wrapper that adds a draggable header to the top of the app.
 *
 * This won't be rendered in the browser
 * @returns
 */
export function DesktopAppWrapper({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="header" />
      {children}
    </>
  )
}
